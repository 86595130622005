import { AfterViewInit, Component, Input, NgZone, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { VehicleSpecsService } from 'src/app/services/vehicle-specs/vehicle-specs.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { VehicleDescription } from 'src/app/models/vehicle-description';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { pondOptions, pondOptionsMultiple } from 'src/app/configs/filepond';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { pairwise, startWith } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environmentAVPInruilmodule } from 'src/environments/environment';
import { FormComponent } from '../../form/form.component';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-template-2134',
  templateUrl: './template-2134.component.html',
  styleUrls: ['./template-2134.component.scss'],
  animations: [
    trigger('fade', [
      state('false', style({ 'opacity': 0.3, 'pointer-events': 'none' })),
      state('true', style({ opacity: 1 })),
      transition('false => true', animate('500ms ease-in')),
    ])
  ]
})

export class Template2134Component implements OnInit, AfterViewInit {
  @ViewChild('myPond') myPond: any;
  @Input() isResuming!: boolean;
  @Input() template!: number;
  @Input() parameters!: any;
  @Input() authReady!: any;

  public images: any = {
    front_left: { placeholder: "../../../../assets/images/templates/2134/icon-front.svg", current: "", valid: false },
    rear_right: { placeholder: "../../../../assets/images/templates/2134/icon-back.svg", current: "", valid: false },
    interior: { placeholder: "../../../../assets/images/templates/2134/icon-interior.svg", current: "", valid: false },
    mileage: { placeholder: "../../../../assets/images/templates/2134/icon-dashboard.svg", current: "", valid: false },
    dashboard: { placeholder: "../../../../assets/images/templates/2134/icon-dashboard_full.svg", current: "", valid: false },
    other: { placeholder: "../../../../assets/images/templates/2134/icon-damages.svg", current: "", valid: true },
  };

  public loading = false;
  public showResumeLaterText = false;
  public validationError = false;
  public apiError = false;
  public errorMessages: any;
  public skeletonLoading = true;
  public pageDetails!: null | VehicleDescription;
  public currentStep = 1;
  public carPreviewImage = null;
  public pondOptions = pondOptions;
  public pondOptionsMultiple = pondOptionsMultiple;
  public form: FormGroup | null | undefined;
  public currentPhoto = '';
  private pages: any = [];
  public currentDescriptionId: number | undefined;
  public currentRegistration: string | undefined;
  public submitPressed = false;

  public options = [
    {
      id: 1,
      selected: false,
      description: 'Navigatie'
    },
    {
      id: 2,
      selected: false,
      description: 'Airco'
    },
    {
      id: 3,
      selected: false,
      description: 'Meeleveren winterbanden (naast de zomerbanden)'
    },
    {
      id: 4,
      selected: false,
      description: 'Sportstoelen'
    },
    {
      id: 5,
      selected: false,
      description: 'Metallic / parelmoer lak'
    },
    {
      id: 6,
      selected: false,
      description: 'Led / xenon koplampen'
    },
  ];

  constructor(
    private vehicleSpecs: VehicleSpecsService,
    private utils: UtilsService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private _ngZone: NgZone,
    private renderer: Renderer2,
    private formController: FormComponent) {
    this.form = new FormGroup({});
  }

  ngAfterViewInit() {
    this.loadGTM();
  }

  private loadGTM() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-TBTKDGG';
    this.renderer.appendChild(document.head, script);

    // Optionally, add the GTM initialization script if needed
    const inlineScript = this.renderer.createElement('script');
    inlineScript.type = 'text/javascript';
    inlineScript.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TBTKDGG');
    `;
    this.renderer.appendChild(document.head, inlineScript);
  }

  ngOnInit(): void {
    // Google Analytics tracking code
    var s = document.createElement('script');
    s.type = "text/javascript";
    s.async = true;
    s.src = 'https://www.googletagmanager.com/gtag/js?id=G-TE745MXFVH';
    document.head.appendChild(s);

    var c = document.createElement('script');
    c.type = "text/javascript";
    c.innerHTML = `
      (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "eupbtcbe3p");`;
    document.head.appendChild(c);

    // Set placeholder images
    for (const key in this.images) {
      this.images[key].current = this.images[key].placeholder;
    }

    this.pondOptions.allowImagePreview = false;

    // Set filepond photo upload options
    this.pondOptionsMultiple.server = {
      process: (fieldName: any, file: Blob, metadata: any, load: (arg0: any) => void, error: (arg0: string) => void, progress: any, abort: any, transfer: any, options: any) => {
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (event: Event) => {
          const photoPosition = this.currentPhoto;
          // Convert the binary string to a blob
          const blob = new Blob([reader.result as string], { type: file.type });
          this.vehicleSpecs.addVehicleDescriptionMedia(Number(this.currentDescriptionId), 'image', 'vehicle', blob, undefined, undefined, photoPosition).then(data => {
            if (data.data != null) {
              this.images[data.data.PositionType.name].valid = true;
              this.countValidImages();
              load(data.data.id)
            } else {
              error('');
              this.handleImageUploadError();
            }
          })
        }
        return;
      },
      revert: (uniqueFileId: number, load: () => void, error: any) => {
        this.vehicleSpecs.deleteVehicleDescriptionImage(Number(this.currentDescriptionId), uniqueFileId).then(data => {
          // Set placeholder image
          this.images[this.currentPhoto].current = this.images[this.currentPhoto].placeholder;
          this.countValidImages();
          load();
        })
      },
    }

    this.pondOptions.server = {
      process: (fieldName: any, file: Blob, metadata: any, load: (arg0: any) => void, error: (arg0: string) => void, progress: any, abort: any, transfer: any, options: any) => {
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (event: Event) => {
          const photoPosition = this.currentPhoto;
          // Convert the binary string to a blob
          const blob = new Blob([reader.result as string], { type: file.type });
          this.vehicleSpecs.addVehicleDescriptionMedia(Number(this.currentDescriptionId), 'image', 'vehicle', blob, undefined, undefined, photoPosition).then(data => {
            if (data.data != null) {
              if (blob.type == 'image/heic') {
                this.images[data.data.PositionType.name].current = '../../../assets/images/loader.gif';
                setTimeout(() => {
                  this._ngZone.run(() => {
                    this.images[data.data.PositionType.name].current = data.data.image
                  })
                }, 6000);
              } else {
                // Read the file again to display in FE
                reader.readAsDataURL(file);
                reader.onloadend = (event: Event) => {
                  this._ngZone.run(() => {
                    this.images[data.data.PositionType.name].current = reader.result
                  });
                };
              }
              this.images[data.data.PositionType.name].valid = true;
              this.countValidImages();
              load(data.data.id)
            } else {
              error('');
              this.handleImageUploadError();
            }
          })
        }
        return;
      },
      revert: (uniqueFileId: number, load: () => void, error: any) => {
        this.vehicleSpecs.deleteVehicleDescriptionMedia(Number(this.currentDescriptionId), uniqueFileId).then(data => {
          // Set placeholder image
          this.images[this.currentPhoto].current = this.images[this.currentPhoto].placeholder;
          this.countValidImages();
          load();
        })
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    if (changes['authReady']) {
      if (changes['authReady'].currentValue == true) {
        this.loadContent();
      }
    }
  }

  private handleImageUploadError() {
    this.snackBar.open('Er is een fout opgetreden bij het uploaden van de foto. Probeer het opnieuw', 'Sluiten')
  }

  private loadContent() {
    this.skeletonLoading = true;
    // Check if we are resuming the valuation (from link in email)
    if (this.isResuming == true) {
      console.log('Resuming description (from link in email)');
      this.resumeVehicleDescriptionFromEmail();
    } else {
      // No previous data is present, create new description
      if (localStorage.getItem('navigationData') == null) {
        console.log('Starting new description (no data present)')
        this.startVehicleDescription();
      } else {
        // There is stored data. Set the data
        this.setLocalSpecData();
        // Check if stored data and url data are the same
        const parsedUrlData = JSON.parse(atob(this.parameters));

        if (parsedUrlData.v_registration?.replace(/[-]/g, '') === this.currentRegistration?.replace(/[-]/g, '')) {
          // Get fresh data and continue description
          console.log('Resuming description')
          this.resumeVehicleDescription();
        } else {
          // Registration is not the same. Start new spec
          console.log('Starting new description')
          this.startVehicleDescription();
        }
      }
    }
  }

  private resumeVehicleDescriptionFromEmail() {
    const parameters = (JSON.parse(atob(this.parameters)).general);
    this.currentDescriptionId = parameters.description_id;
    localStorage.setItem('descriptionToken', parameters.description_token);

    // Get page structure
    this.vehicleSpecs.getPages(['name'], [{ field: "sequence", direction: "ASC" }], { "AND": [["name", "neq", "start"]] }, ['requiredPages']).then(navigationData => {
      for (const page of navigationData) {
        this.pages.push(page.name)
      }

      if (parameters.page_name == 'fotos') {
        console.log("Resume vehicle description from email (fotos)");
        this.vehicleSpecs.getVehicleDescriptionById(Number(this.currentDescriptionId)).then(data => {
          this.saveLocalSpecData();
          // Get navigation data for the step in memory
          this.vehicleSpecs.getPageByName(Number(this.currentDescriptionId), "fotos").then(data => {
            this.pageDetails = data;
            this.populateFormElements();
            // Set current step
            this.currentStep = this.pages.findIndex((page: string) => page == "fotos") + 1;
            this.saveLocalSpecData();
            this.skeletonLoading = false;
            this.loading = false;
          }, error => {
            localStorage.clear();
            this.apiError = true;
            this.skeletonLoading = false;
            this.loading = false;
          })
        }, error => {
          localStorage.clear();
          this.apiError = true;
          this.skeletonLoading = false;
          this.loading = false;
        });
      } else {
        this.vehicleSpecs.getVehicleDescriptionById(Number(this.currentDescriptionId)).then(vehicleDescriptionData => {
          console.log("Resume vehicle description from email (no page set)");
          this.saveLocalSpecData();
          // Get navigation data for the step in memory
          this.vehicleSpecs.getPageByName(Number(this.currentDescriptionId), vehicleDescriptionData.data.lastPage).then(data => {
            this.pageDetails = data;
            this.populateFormElements();
            // Set current step
            this.currentStep = this.pages.findIndex((page: string) => page == vehicleDescriptionData.data.lastPage) + 1;
            console.log("Setting step to: ", this.currentStep)
            this.skeletonLoading = false;
            this.loading = false;
          }, error => {
            localStorage.clear();
            this.apiError = true;
            this.skeletonLoading = false;
            this.loading = false;
          })
        }, error => {
          localStorage.clear();
          this.apiError = true;
          this.skeletonLoading = false;
          this.loading = false;
        });
      }
    });
  }

  private startVehicleDescription() {
    // We need to be sure that we are on the first step
    this.currentStep = 1;
    this.saveLocalSpecData();
    this.vehicleSpecs.startVehicleDescription({ fields: this.parameters, skipHistoricRequestCheck: false, pageDetails: true }).then(data => {
      if (data.error == false) {
        this.currentDescriptionId = data.descriptionId;
        // Get page structure
        this.vehicleSpecs.getPages(['name'], [{ field: "sequence", direction: "ASC" }], { "AND": [["required", "eq", true], ["name", "neq", "start"]] }).then(navigationData => {
          for (const page of navigationData) {
            this.pages.push(page.name)
          }
          this.pageDetails = data;
          this.currentRegistration = (this.utils.getSingleFieldFromSpec(this.pageDetails, 'v_registration')?.value);
          this.saveLocalSpecData();
          this.populateFormElements();
          this.skeletonLoading = false;
          this.loading = false;
        }, error => {
          localStorage.clear();
          this.apiError = true;
          this.skeletonLoading = false;
          this.loading = false;
        })
      } else {
        // Something is wrong with this valuation
        localStorage.clear();
        this.errorMessages = data.message;
        this.validationError = true;
        this.skeletonLoading = false;
        this.loading = false;
      }
    }, error => {
      this.skeletonLoading = false;
      this.loading = false;
    });
  }

  private resumeVehicleDescription() {
    // Get page structure
    this.vehicleSpecs.getPages(['name'], [{ field: "sequence", direction: "ASC" }], { "AND": [["required", "eq", true], ["name", "neq", "start"]] }).then(navigationData => {
      for (const page of navigationData) {
        this.pages.push(page.name)
      }
      // Get navigation data for the step in memory
      this.vehicleSpecs.getPageByName(Number(this.currentDescriptionId), this.pages[this.currentStep - 1]).then(data => {
        this.pageDetails = data;
        this.populateFormElements();
        this.skeletonLoading = false;
        this.loading = false;
      }, error => {
        localStorage.clear();
        this.apiError = true;
        this.skeletonLoading = false;
        this.loading = false;
      })
    }, error => {
      this.skeletonLoading = false;
      this.loading = false;
    });
  }

  private setLocalSpecData() {
    if (localStorage.getItem('navigationData') != null) {
      const data = JSON.parse(localStorage.getItem('navigationData') || '{}');
      this.currentDescriptionId = data.descriptionId;
      this.currentStep = data.currentStep;
      this.currentRegistration = data.registration;
    }
  }

  private saveLocalSpecData() {
    localStorage.setItem('navigationData', JSON.stringify({ descriptionId: this.currentDescriptionId, currentStep: this.currentStep, registration: this.currentRegistration }));
    this.setLocalSpecData();
  }

  // This function is used to set details of the current image uploading.
  public setPhotoType(event: any, name: string) {
    this.currentPhoto = name;
  }

  public nextStep() {
    this.submitPressed = true;
    if (this.form?.valid) {
      this.skeletonLoading = true
      this.loading = true;
      this.pageDetails = null;
      window.parent.postMessage('scroll', environmentAVPInruilmodule.frameUrl);
      this.setLocalSpecData();
      const request = {
        fields: this.utils.filterChangedFormFields(this.form),
        pageDetails: true,
        pageName: this.pages[this.currentStep]
      }
      this.vehicleSpecs.updateVehicleDescription(this.currentDescriptionId!, request).then(data => {
        if (data.error == false) {
          this.currentStep++;
          this.saveLocalSpecData();
          this.pageDetails = data;
          this.populateFormElements();
          this.skeletonLoading = false;
          this.submitPressed = false;
          this.loading = false;
        } else {
          this.errorMessages = data.error;
          this.apiError = true;
          this.loading = false
        }
      })
    } else {
      for (const controlName in this.form?.controls) {
        if (this.form?.controls.hasOwnProperty(controlName)) {
          this.form?.controls[controlName].markAsTouched();
          this.formController.checkValidity();
        }
      }
    }
  }

  public previousStep() {
    this.skeletonLoading = true
    this.loading = true;
    this.pageDetails = null;
    window.parent.postMessage('scroll', environmentAVPInruilmodule.frameUrl);
    this.setLocalSpecData();
    // Get navigation data for the step in memory
    this.vehicleSpecs.getPageByName(Number(this.currentDescriptionId), this.pages[this.currentStep - 2]).then(data => {
      this.currentStep -= 1;
      this.saveLocalSpecData();
      this.pageDetails = data;
      this.populateFormElements();
      this.skeletonLoading = false;
      this.loading = false;
    }, error => {
      this.skeletonLoading = false;
      this.loading = false;
    });
  }

  public cancelRequest() {
    localStorage.clear();
    window.parent.postMessage('home', environmentAVPInruilmodule.frameUrl);
  }

  public resumeSpecification() {
    window.location.reload();
  }

  // Function that is triggered when submitting the valuation (in UI)
  public submitValuation() {
    this.loading = true;
    // Check if fields have been changed
    const changedFields = this.utils.filterChangedFormFields(this.form);
    if (Object.keys(changedFields).length === 0) {
      this.finishValuation();
    } else {
      const request = {
        fields: changedFields,
        pageDetails: false,
        pageName: this.pages[this.currentStep]
      }
      // Update changed data
      this.vehicleSpecs.updateVehicleDescription(this.currentDescriptionId!, request).then(data => {
        this.finishValuation();
      })
    }
  }

  // Function that actually finishes the valuation
  private finishValuation() {
    this.vehicleSpecs.finishVehicleDescription(Number(this.currentDescriptionId)).then(data => {
      if (data.error == false) {
        // Push all data to Tag Manager in one go
        window['dataLayer'] = window['dataLayer'] || [];

        const eventData = {
          email: this.form?.controls['c_emailAddress']?.value || '',
          phone: this.formatPhoneNumber(this.form?.controls['c_phone']?.value || ''),
          valuation_id: data.data.id,
          event: 'valuation_submit'
        };

        window['dataLayer'].push(eventData);
        console.log('Pushed data to dataLayer:', eventData);

        // Clear localStorage
        localStorage.clear();

        // Delay the redirect to ensure the dataLayer.push has time to process
        setTimeout(() => {
          parent.window.location.href = data.data.redirectUrl;
        }, 2000);
      } else {
        this.loading = false;
        this.errorMessages = data.error;
        this.apiError = true;
      }
    });
  }

  // This function is used to create a form control wih all custom fields for validation
  private populateFormElements() {
    this.carPreviewImage = this.utils.getVehiclePreviewImage(this.pageDetails);
    // Reset form
    this.form = new FormGroup({});
    if (this.pageDetails!.fields != null) {
      for (let field of this.pageDetails!.fields) {
        if (field.required == true) {
          this.form?.addControl(field.Field.name, new FormControl(field.value, Validators.required));
        } else {
          this.form?.addControl(field.Field.name, new FormControl(field.value, null));
        }

        if (field.Field.name == 'vs_mileageType') {
          this.form?.controls['vs_mileageType'].setValue('km');
          this.form.controls['vs_mileageType'].markAsDirty();
        }

        if (field.Field.name == 'vs_vatMarginTypeId') {
          this.form?.addControl('vs_vatMarginTypeId_question', new FormControl(field.value, [Validators.required]));
        }

        // Set selected options
        if (field.Field.name == 'vs_commentAccessories') {
          // Check boxes based on selection
          if (field.value != null) {
            var splits = field.value.split(",");
            for (const item of splits) {
              const index = this.options.findIndex(option => option.description == item.trim());
              if (index != -1) {
                this.options[index].selected = true;
              }
            }
          }
        }

        // Load images that have been uploaded.
        if (this.pages[this.currentStep - 1] == "fotos" && field.Field.name == 'vs_images') {
          if (field.value != null) {
            for (const image of field.value) {
              if (this.images[image.PositionType.name]) {
                this.images[image.PositionType.name].current = image.image
                this.images[image.PositionType.name].valid = true;
              }
            }
          }
          // This (hidden) form field is used to track the number of required images
          this.form?.addControl('images_count', new FormControl(null, [Validators.required, Validators.min(5)]));
          this.countValidImages();
        }
      }
    }

    Object.keys(this.form.controls).forEach(key => {
      this.form!.controls[key].valueChanges.pipe(startWith(null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          this.form?.controls[key].markAsDirty();
        });
    });
  }

  // Event when a form field has changed, update the form control.
  public formChange(event: FormGroup) {
    for (let field in event.controls) {
      if (event.controls[field].dirty == true) {
        this.form?.controls[field].setValue(event.controls[field].value);
      }
    }
  }

  public updateVatMarginType(event: any) {
    if (event == '1') {
      this.form?.controls['vs_vatMarginTypeId'].setValue(null);
      this.form?.controls['vs_vatMarginTypeId'].setValidators([Validators.required]);
      this.form?.controls['vs_vatMarginTypeId'].updateValueAndValidity();
      this.form?.controls['vs_vatMarginTypeId_question'].markAsPristine();
    } else {
      this.form?.controls['vs_vatMarginTypeId'].setValue(2);
      this.form?.controls['vs_vatMarginTypeId'].clearValidators();
      this.form?.controls['vs_vatMarginTypeId'].updateValueAndValidity();
      this.form?.controls['vs_vatMarginTypeId_question'].markAsPristine();
    }
  }

  public optionChanged(checked: any, id: number) {
    // Add to array
    this.options[this.options.findIndex(option => option.id == id)].selected = checked;

    // Add to form
    let optionsString = "";
    this.options.filter(option => option.selected === true).forEach((element, index) => {
      if (index == 0) {
        optionsString = element.description;
      } else {
        optionsString = optionsString + ", " + element.description;
      }
    });
    this.form?.controls['vs_commentAccessories'].setValue(optionsString);
  }

  public resumeLater() {
    this.loading = true
    this.vehicleSpecs.resumeLater(Number(this.currentDescriptionId), this.pages[this.currentStep - 1]).then(result => {
      this.loading = false
      this.showResumeLaterText = true;
    })
  }

  public countValidImages() {
    let count = 0;
    for (const index in this.images) {
      if (this.images[index].valid == true) {
        count++;
      }
    }
    this.form?.controls['images_count'].setValue(count);
  }

  public registerToMailchimpNewsletter(event: any) {
    if (event.checked == true) {
      interface MailChimpResponse {
        result: string;
        msg: string;
      }

      const params = new HttpParams()
        .set('MERGE0', this.utils.getSingleFieldFromSpec(this.pageDetails, 'c_emailAddress')?.value)
        .set('MERGE1', this.utils.getSingleFieldFromSpec(this.pageDetails, 'c_salutationTypeId')?.value)
        .set('MERGE2', this.utils.getSingleFieldFromSpec(this.pageDetails, 'c_lastName')?.value)

      const mailChimpUrl = 'https://autoverkooppartner.us1.list-manage.com/subscribe/post-json?u=8d7adb2bdc18cd6e800a0affe&id=322c86a72e&' + params.toString();

      // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
      this.http.post<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
        console.log('Subscribed to mailing list.')
      }, error => {
        console.error(error);
      });
    }
  }

  private formatPhoneNumber(phoneNumber: string) {
    if (phoneNumber == null) {
      return null;
    }

    // Remove all non-digits from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    // If the phone number starts with "0", replace it with "+31"
    if (digitsOnly.startsWith('0')) {
      return '+31' + digitsOnly.substr(1);
    }

    // If the phone number starts with "+31", return it as is
    if (digitsOnly.startsWith('31')) {
      return '+' + digitsOnly;
    }

    return null;
  }
}
