import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FieldElement } from 'src/app/models/vehicle-description';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, AfterViewInit {
  @Output() formChange = new EventEmitter<FormGroup>();

  @Input() fields: FieldElement[] | undefined;
  @Input() show: any;
  @Input() hint: string | undefined;

  // Our main form controller.
  form: FormGroup;
  templateId: number = 0;
  today = new Date();

  constructor(private route: ActivatedRoute) {
    // Init empty form
    this.form = new FormGroup({});
  }

  ngOnInit(): void {
    this.templateId = Number(this.route.snapshot.paramMap.get('templateId'));
    this.populateFormElements();
  }

  checkValidity() {
    for (const controlName in this.form?.controls) {
      if (this.form?.controls.hasOwnProperty(controlName)) {
        this.form?.controls[controlName].markAsTouched();
      }
    }
  }

  getErrorMessage(field: string) {
    if (this.form.controls[field].hasError('required')) {
      return 'Dit is een verplicht veld';
    }
    return this.form.controls[field].hasError('email') ? 'Dit is geen geldig email-adres' : '';
  }

  ngAfterViewInit(): void {
    // Register form change event
    this.form.valueChanges.subscribe(x => {
      this.formChange.emit(this.form);
    })
  }

  private populateFormElements() {
    if (!this.fields) return;

    const filteredFormFields = this.fields.filter(field => {
      if (!this.show) return true;
      return this.show.includes(field.Field.name);
    });

    for (const field of filteredFormFields) {
      const validators = field.required ? [Validators.required] : [];
      this.form.addControl(field.Field.name, new FormControl(field.value, validators));
    }

    this.fields = filteredFormFields;
  }

  public getColorByProbability(probability: number | any): string {
    const probabilityNumber = Number(probability);
    if (probabilityNumber > 70 && probabilityNumber <= 100) {
      return 'green';
    } else if (probabilityNumber >= 50 && probabilityNumber <= 70) {
      return 'orange';
    } else {
      return 'lightgrey';
    }
  }

  public mapOptionalOptions(input: any): string {
    const { options, value } = input;

    // Filter options that match any ID in the value array
    const matchedOptions = options.filter((option: { id: any; }) => value.includes(option.id));

    // Map the matched options to their descriptionNl and join them with a comma
    const descriptions = matchedOptions.map((option: { descriptionNl: any; }) => option.descriptionNl).join(', ');

    return descriptions;
  }
}
