<!-- Fields that do not need the form element -->
<ng-container *ngFor="let formElement of fields" [ngSwitch]="formElement.FieldType.name">
    <span *ngSwitchCase="'readonly'">
        <ng-container *ngIf="templateId == 2134">
            <ng-container [ngSwitch]="formElement.Field.name">
                <ng-container *ngSwitchCase="'vs_fuelTypeId'">
                    {{formElement.descriptionNl | capitalize}}
                </ng-container>
                <ng-container *ngSwitchCase="'v_registration'">
                    {{formElement.value | registration: formElement.value}}
                </ng-container>
                <ng-container *ngSwitchDefault>
                    {{formElement.value}}
                </ng-container>
                <ng-container *ngSwitchCase="'vs_images'" appearance="fill">
                    <div class="image-row">
                        <div class="image-column" *ngFor="let image of formElement.value">
                            <img [src]="image.thumbnail">
                            <div class="image-caption">{{image.PositionType.descriptionNl}}</div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="templateId == 108">
            <ng-container [ngSwitch]="formElement.Field.name">
                <div class="label-row" *ngSwitchCase="'vs_vehicleStatus'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">
                        Bedrijfsvoorraad: {{formElement.descriptionNl?.bedrijfsvoorraad}},
                        Dubbele cabine: {{formElement.descriptionNl?.dubbelecabine}},
                        Ongeldig: {{formElement.descriptionNl?.ongeldig}},
                        Taxi: {{formElement.descriptionNl?.taxi}},
                        Status: <span *ngIf="!formElement.descriptionNl?.status">Onbekend</span> <span
                            *ngIf="formElement.descriptionNl?.status">{{formElement.descriptionNl?.status}}</span>
                    </div>
                </div>

                <div class="label-row" *ngSwitchCase="'v_officialImport'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">
                        <span *ngIf="formElement.value">Ja</span>
                        <span *ngIf="!formElement.value">Nee</span>
                    </div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_mileageHistoryCheckStatusTypeId'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl || 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_cylinderVolume'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl || 'Onbekend' }}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_transmissionTypeId'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl|| 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_smoked'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl || 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_pets'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl || 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_conditionInteriorTypeId'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl || 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_conditionBodyTypeId'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl|| 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'ds_repairCosts'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input" [innerHTML]="formElement.descriptionNl"></div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_maintenanceHistoryTypeId'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl || 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_lastMaintenanceMileage'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl || 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_tiresTypeId'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl || 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_timingBeltReplacedTypeId'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl || 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_mileageDelivery'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl|| 'Onbekend' | capitalize}} km</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_vehicleTypeId'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl|| 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_fuelTypeId'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl || 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_bodyTypeId'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl || 'Onbekend' | capitalize}}</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_restBpm'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input" [innerHTML]="formElement.descriptionNl"></div>
                </div>

                <div class="label-row" *ngSwitchCase="'v_dateFirstAdmission'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">
                        {{ formElement.descriptionNl || 'Onbekend' }}
                    </div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_okrStatusTypeId'">
                    <div class="label-label no_margin_top">Oordeel tellerstand</div>
                    <div class="label-input">
                        {{ formElement.descriptionNl || 'Onbekend' }}
                    </div>
                </div>

                <div class="label-row" *ngSwitchCase="'v_dateFirstRegistration'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">
                        {{ formElement.descriptionNl || 'Onbekend' }}
                    </div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_dateSecondRegistration'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">
                        {{ formElement.descriptionNl || 'Onbekend' }}
                    </div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_dateApkExpires'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">
                        {{ formElement.descriptionNl || 'Onbekend' }}
                    </div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_modelYear'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">
                        {{ formElement.descriptionNl || 'Onbekend' }}
                    </div>
                </div>

                <div class="label-row" *ngSwitchCase="'v_bpmAmount'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input" [innerHTML]="formElement.descriptionNl"></div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_recalculatedNewPrice'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input" [innerHTML]="formElement.descriptionNl"
                        *ngIf="formElement.descriptionNl != null"></div>
                    <div class="label-input" *ngIf="formElement.descriptionNl == null">Onbekend</div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_power'">
                    <div class="label-label no_margin_top">{{formElement.label}}</div>
                    <div class="label-input">{{formElement.descriptionNl}} </div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_images'">
                    <div class="image-row">
                        <div class="image-column" *ngFor="let image of formElement.value">
                            <img [src]="image.thumbnail">
                        </div>

                        <div *ngIf="formElement.value == null">Er zijn geen foto's geüpload.</div>
                    </div>
                </div>

                <div class="label-row" *ngSwitchCase="'vs_damageImages'">
                    <div class="label-label no_margin_top">Schade foto's</div>
                    <div class="image-row">
                        <div class="image-column" *ngFor="let image of formElement.value">
                            <img [src]="image.thumbnail">
                        </div>
                    </div>
                </div>

                <div class="label-row" *ngSwitchDefault>
                    <div class="label-label no_margin_top">
                        <span *ngIf="formElement.label">{{formElement.label}}</span>
                        <span *ngIf="!formElement.label">Geen label</span>
                    </div>
                    <div class="label-input">
                        <span *ngIf="formElement.descriptionNl">{{formElement.descriptionNl | capitalize}}</span>
                        <span *ngIf="!formElement.descriptionNl && formElement.value">{{formElement.value}}</span>
                        <span *ngIf="!formElement.descriptionNl && !formElement.value">Onbekend</span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </span>
</ng-container>

<!-- Fields that do need the form element -->
<form [formGroup]="form">
    <ng-container *ngFor="let formElement of fields" [ngSwitch]="formElement.FieldType.name">
        <ng-container *ngSwitchCase="'hidden'">
            <ng-container [ngSwitch]="formElement.Field.name">
                <mat-form-field style="display: none;" *ngSwitchCase="'vs_mileageType'">
                    <mat-label>{{formElement.label}}</mat-label>
                    <input [formControlName]="formElement.Field.name" matInput>
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>

                <mat-form-field *ngSwitchDefault appearance="fill">
                    <mat-label>{{formElement.label}}</mat-label>
                    <input [formControlName]="formElement.Field.name" matInput>
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'text'">
            <ng-container [ngSwitch]="formElement.Field.name">
                <mat-form-field *ngSwitchCase="'vs_commentVehicleInfoCustomer'" appearance="fill">
                    <mat-label>{{formElement.label}}</mat-label>
                    <textarea [formControlName]="formElement.Field.name" rows="6" matInput></textarea>
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>

                <mat-form-field *ngSwitchDefault appearance="fill">
                    <mat-label>{{formElement.label}}</mat-label>
                    <input [formControlName]="formElement.Field.name" matInput>
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'boolean'">
            <mat-form-field appearance="fill">
                <mat-label>{{formElement.label | capitalize}}</mat-label>
                <mat-select [formControlName]="formElement.Field.name">
                    <mat-option [value]="false">Nee</mat-option>
                    <mat-option [value]="true">Ja</mat-option>
                </mat-select>
                <mat-error
                    *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                    {{getErrorMessage(formElement.Field.name)}}</mat-error>
                <mat-hint *ngIf="hint">{{hint}}</mat-hint>
            </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="'checkbox'">
            <mat-slide-toggle [formControlName]="formElement.Field.name">{{formElement.label |
                capitalize}}</mat-slide-toggle>
            <mat-error
                *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                {{getErrorMessage(formElement.Field.name)}}</mat-error>
            <mat-hint *ngIf="hint">{{hint}}</mat-hint>
        </ng-container>

        <ng-container *ngSwitchCase="'modelyear_list'">
            <p *ngIf="formElement.options === null">Er zijn geen modellen gevonden voor dit voertuig, controleer
                eventueel de voertuig-gegevens</p>
            <mat-form-field appearance="fill" *ngIf="formElement.options !== null">
                <mat-label>{{formElement.label | capitalize}}</mat-label>
                <mat-select [formControlName]="formElement.Field.name">
                    <mat-option *ngFor="let option of formElement.options" [value]="option.id">
                        <span>{{option.descriptionNl | capitalize}}</span>
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                    {{getErrorMessage(formElement.Field.name)}}</mat-error>
                <mat-hint *ngIf="hint">{{hint}}</mat-hint>
            </mat-form-field>
        </ng-container>

        <ng-container *ngSwitchCase="'select'">
            <ng-container [ngSwitch]="formElement.Field.name">
                <mat-form-field *ngSwitchCase="'vs_mileageType'" appearance="fill">
                    <mat-label>{{formElement.label | capitalize}}</mat-label>
                    <mat-select [formControlName]="formElement.Field.name">
                        <mat-option *ngFor="let option of formElement.options"
                            [value]="option.id">{{option.descriptionNl}}</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>

                <mat-form-field *ngSwitchCase="'vs_atlCode'" appearance="fill">
                    <mat-label>{{formElement.label | capitalize}}</mat-label>
                    <mat-select [formControlName]="formElement.Field.name"
                        [placeholder]="'Selecteer een uitvoering...'">
                        <mat-option [value]="null">Onbekend </mat-option>
                        <mat-option *ngFor="let option of formElement.options" [value]="option.id">
                            <span>{{option.type | capitalize}} {{option.descriptionNl | capitalize}}</span>
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>

                <mat-form-field *ngSwitchDefault appearance="fill">
                    <mat-label>{{formElement.label | capitalize}}</mat-label>
                    <mat-select [formControlName]="formElement.Field.name">
                        <mat-option *ngFor="let option of formElement.options" [value]="option.id">
                            {{option.descriptionNl |
                            capitalize}}</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'date'">
            <ng-container [ngSwitch]="formElement.Field.name">
                <mat-form-field *ngSwitchCase="'vs_mileageType'" appearance="fill">
                    <mat-label>{{formElement.label | capitalize}}</mat-label>
                    <input [max]="today" [formControlName]="formElement.Field.name" matInput [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>

                <mat-form-field *ngSwitchCase="'v_dateFirstAdmission'" appearance="fill">
                    <mat-label>{{formElement.label | capitalize}}</mat-label>
                    <input [max]="today" [formControlName]="formElement.Field.name" matInput [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>

                <mat-form-field *ngSwitchDefault appearance="fill">
                    <mat-label>{{formElement.label | capitalize}}</mat-label>
                    <input [formControlName]="formElement.Field.name" matInput [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'radio'">
            <ng-container [ngSwitch]="formElement.Field.name">
                <mat-label class="radio-group-label">{{formElement.label}}</mat-label>
                <div class="radio-group" fxLayout fxLayoutAlign="left left" fxLayoutGap="30px">
                    <mat-radio-group [formControlName]="formElement.Field.name">
                        <mat-radio-button *ngFor="let option of formElement.options"
                            [value]="option.id">{{option.descriptionNl | capitalize}}</mat-radio-button>
                        <mat-error *ngIf="(form.controls[formElement.Field.name].touched)">
                            {{getErrorMessage(formElement.Field.name)}}</mat-error>
                        <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                    </mat-radio-group>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'numeric'">
            <ng-container [ngSwitch]="formElement.Field.name">
                <mat-form-field *ngSwitchCase="'vs_mileage'" appearance="fill">
                    <mat-label>{{formElement.label | capitalize}}</mat-label>
                    <input [formControlName]="formElement.Field.name" min="0" pattern="[0-9]*" type="number" matInput
                        [placeholder]="formElement.label">
                    <span matSuffix>km</span>
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>

                <mat-form-field *ngSwitchCase="'vs_lastMaintenanceMileage'" appearance="fill">
                    <mat-label>{{formElement.label | capitalize}}</mat-label>
                    <input [formControlName]="formElement.Field.name" min="0" pattern="[0-9]*" type="number" matInput
                        [placeholder]="formElement.label">
                    <span matSuffix>km</span>
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>

                <mat-form-field *ngSwitchDefault appearance="fill">
                    <mat-label>{{formElement.label | capitalize}}</mat-label>
                    <input [formControlName]="formElement.Field.name" type="number" matInput
                        [placeholder]="formElement.label">
                    <mat-error
                        *ngIf="form.controls[formElement.Field.name].invalid && form.controls[formElement.Field.name].touched">
                        {{getErrorMessage(formElement.Field.name)}}</mat-error>
                    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
                </mat-form-field>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'typecommercial_list'">
            <mat-form-field appearance="fill">
                <mat-label>Selecteer een uitvoering...</mat-label>
                <mat-select [formControlName]="formElement.Field.name">
                    <mat-option [value]="'null'">Onbekend</mat-option>
                    <mat-option *ngFor="let option of formElement.options" [value]="option.id">
                        <!-- Here we are calling the method defined in the component -->
                        <span [style.color]="getColorByProbability(option.probabilityPercentage)">
                            {{option.type | capitalize}} {{option.descriptionNl | capitalize}}
                            <!-- Displaying 'meest waarschijnlijk' only if probability is 100 -->
                            <b *ngIf="option.probabilityPercentage == 100">(meest waarschijnlijk)</b>
                        </span>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="(form.controls[formElement.Field.name].touched)">
                    {{getErrorMessage(formElement.Field.name)}}</mat-error>
                <mat-hint *ngIf="hint">{{hint}}</mat-hint>
            </mat-form-field>
        </ng-container>
    </ng-container>
</form>