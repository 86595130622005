<title>Auto Verkoop Partner Invoer</title>

<div class="container">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15%">
    <img *ngIf="currentStep >= 1" fxFlex="20" class="header-logo"
      src="assets/images/templates/2134/navigation/step-1-completed.png">
    <img *ngIf="currentStep >= 2" fxFlex="20" class="header-logo"
      src="assets/images/templates/2134/navigation/step-2-completed.png">
    <img *ngIf="currentStep >= 3" fxFlex="20" class="header-logo"
      src="assets/images/templates/2134/navigation/step-3-completed.png">
    <img *ngIf="currentStep >= 4" fxFlex="20" class="header-logo"
      src="assets/images/templates/2134/navigation/step-4-completed.png">

    <img *ngIf="currentStep < 1" fxFlex="20" class="header-logo"
      src="assets/images/templates/2134/navigation/step-1.png">
    <img *ngIf="currentStep < 2" fxFlex="20" class="header-logo"
      src="assets/images/templates/2134/navigation/step-2.png">
    <img *ngIf="currentStep < 3" fxFlex="20" class="header-logo"
      src="assets/images/templates/2134/navigation/step-3.png">
    <img *ngIf="currentStep < 4" fxFlex="20" class="header-logo"
      src="assets/images/templates/2134/navigation/step-4.png">
  </div>
</div>

<div class="header-footer">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>

<div *ngIf="validationError" class="main-container">
  <mat-card class="example-card">
    <mat-card-header fxLayoutAlign="center center">
      <img class="ico" src="../../../../assets/images/notify-icon.png">
      <mat-card-title>Dit voertuig kan niet ingevoerd worden</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="center center">
      <ul *ngFor="let message of errorMessages">
        <li *ngIf="message.descriptionNl">{{message.descriptionNl}}</li>
        <li *ngIf="!message.descriptionNl">{{message}}</li>
      </ul>
    </mat-card-content>
  </mat-card>

  <div style="padding-top: 50px;" fxLayout="row" fxLayoutAlign="center center">
    <button (click)="cancelRequest()" mat-raised-button color="primary">Ga terug</button>
  </div>
</div>

<div *ngIf="apiError" class="main-container">
  <mat-card class="example-card">
    <mat-card-header fxLayoutAlign="center center">
      <img class="ico" src="../../../../assets/images/notify-icon.png">
      <mat-card-title>Er is een fout opgetreden</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="center center">
      <div>
        <p>Helaas is het niet (meer) mogelijk om deze aanvraag af te ronden, omdat: </p>
        <ul>
          <li>De aanvraag al is afgerond</li>
          <li>U niet gemachtigd bent om deze aanvraag te doen</li>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>

  <div style="padding-top: 50px;" fxLayout="row" fxLayoutAlign="center center">
    <button (click)="cancelRequest()" mat-raised-button color="primary">Ga terug</button>
  </div>
</div>

<div class="main-container" *ngIf="!validationError && !apiError">
  <div fxLayout.xs="column" [@fade]="!loading" fxLayout="row" fxLayoutGap="5%">
    <!-- First step -->
    <div class="container" *ngIf="currentStep == 1" fxFlex="50">
      <h2>Vul de gegevens van jouw auto in</h2>
      <p>Zorg dat de omschrijving juist en volledig is. Kloppen al jouw
        gegevens? Dan is het bod dat je van ons krijgt
        gegarandeerd.</p>

      <app-skeleton-loader *ngIf="skeletonLoading"></app-skeleton-loader>

      <!-- Generate dynamic fields -->
      <ng-container *ngIf="pageDetails">
        <section>
          <app-form (formChange)="formChange($event)" [show]="['vs_mileage']" [fields]="pageDetails.fields"></app-form>
        </section>

        <section>
          <app-form (formChange)="formChange($event)" [show]="['vs_atlCode']" [fields]="pageDetails.fields">
          </app-form>
        </section>

        <mat-label>Opties</mat-label>
        <section class="checkbox-grid">
          <mat-checkbox *ngFor="let option of options" [checked]="option.selected" id="{{option.id}}"
            (change)="optionChanged($event.checked, option.id)">{{option.description}}</mat-checkbox>
        </section>

        <section>
          <form *ngIf="form" [formGroup]="form">
            <section *ngIf="form && form.get('vs_vatMarginTypeId_question')">
              <mat-form-field appearance="fill">
                <mat-label>Staat de auto op naam van uw bedrijf?</mat-label>
                <mat-select (ngModelChange)="updateVatMarginType($event)" formControlName="vs_vatMarginTypeId_question">
                  <mat-option [value]="1">Ja</mat-option>
                  <mat-option [value]="2">Nee</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="(form!.controls['vs_vatMarginTypeId_question'].dirty || form!.controls['vs_vatMarginTypeId_question'].touched)">
                  Staat de auto op naam van uw bedrijf is een veld</mat-error>
              </mat-form-field>
            </section>

            <section
              *ngIf="form && form.get('vs_vatMarginTypeId') && form.controls['vs_vatMarginTypeId_question'].value == '1'">
              <mat-form-field appearance="fill">
                <mat-label>Bent u met uw bedrijf BTW plichtig?</mat-label>
                <mat-select formControlName="vs_vatMarginTypeId">
                  <mat-option [value]="1">Ja</mat-option>
                  <mat-option [value]="2">Nee</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="(form!.controls['vs_vatMarginTypeId'].dirty || form!.controls['vs_vatMarginTypeId'].touched)">
                  Staat de auto op naam van uw bedrijf is een veld</mat-error>
              </mat-form-field>
            </section>
          </form>
        </section>

        <section>
          <form *ngIf="form" [formGroup]="form">
            <section *ngIf="form && form.get('vs_conditionBodyTypeId')">
              <mat-form-field appearance="fill">
                <mat-label>Conditie buitenkant</mat-label>
                <mat-select value="" formControlName="vs_conditionBodyTypeId">
                  <mat-option value="3">Goede conditie</mat-option>
                  <mat-option value="2">Redelijke conditie</mat-option>
                  <mat-option value="1">Slechte conditie</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="(form!.controls['vs_conditionBodyTypeId'].dirty || form!.controls['vs_conditionBodyTypeId'].touched)">
                  Conditie buitenkant is een verplicht veld</mat-error>
              </mat-form-field>
            </section>
          </form>

          <form *ngIf="form" [formGroup]="form">
            <section *ngIf="form && form.get('vs_conditionTechnicalTypeId')">
              <mat-form-field appearance="fill">
                <mat-label>Conditie technisch</mat-label>
                <mat-select value="" formControlName="vs_conditionTechnicalTypeId">
                  <mat-option value="3">Goede conditie</mat-option>
                  <mat-option value="2">Redelijke conditie</mat-option>
                  <mat-option value="1">Slechte conditie</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="(form!.controls['vs_conditionTechnicalTypeId'].dirty || form!.controls['vs_conditionTechnicalTypeId'].touched)">
                  Conditie technisch is een verplicht veld</mat-error>
              </mat-form-field>
            </section>
          </form>

          <section
            *ngIf="(form && form.get('vs_conditionTechnicalTypeId')) && form!.controls['vs_conditionTechnicalTypeId'].value == 1">
            <app-form (formChange)="formChange($event)" [show]="['vs_commentTechnicalDefects']"
              [fields]="pageDetails.fields">
            </app-form>
          </section>
        </section>

        <section>
          <app-form (formChange)="formChange($event)" [show]="['vs_smoked', 'vs_pets']" [fields]="pageDetails.fields">
          </app-form>
        </section>
      </ng-container>
    </div>

    <!-- Second step -->
    <div class="container" *ngIf="currentStep == 2" fxFlex="50">
      <h2>Vul jouw persoonlijke gegevens in</h2>
      <p>Wij gaan vertrouwelijk met je gegevens om en gebruiken ze alleen voor deze aanvraag.</p>

      <ng-container *ngIf="pageDetails">
        <section>
          <app-form (formChange)="formChange($event)" [show]="['c_salutationTypeId', 'c_lastName', 'c_city']"
            [fields]="pageDetails.fields"></app-form>
        </section>

        <section>
          <app-form [hint]="'Wij hebben uw email nodig om biedingen met u te communiceren.'"
            (formChange)="formChange($event)" [show]="['c_emailAddress']" [fields]="pageDetails.fields"></app-form>
        </section>

        <section>
          <app-form
            [hint]="'Uw telefoonnummer kan makkelijk zijn als wij vragen hebben over uw auto. Dit veld is niet verplicht'"
            (formChange)="formChange($event)" [show]="['c_phone']" [fields]="pageDetails.fields"></app-form>
        </section>
      </ng-container>
    </div>

    <!-- Thirth step -->
    <div class="container" *ngIf="currentStep == 3 && skeletonLoading == false && showResumeLaterText == false"
      fxFlex="100">
      <h2>Maak foto's van jouw auto en upload deze</h2>
      <a href="javascript:void(0)" (click)="resumeLater()">Of kies voor later toevoegen</a><br>
      <p>Een paar tips voor het beste resultaat: </p>
      <ul>
        <li>Maak de foto's overdag.</li>
        <li>Zorg dat de auto er schoon en netjes uitziet.</li>
        <li>Maak de foto's zoals op de plaatjes is aangegeven.</li>
        <li>Hoe meer foto's je toevoegt, hoe beter.</li>
      </ul>
      <!-- Rest of the content of this step is down below -->
    </div>

    <!-- Second step -->
    <div class="container" *ngIf="currentStep == 4" fxFlex="50">
      <h2>Controleren en versturen</h2>
      <p>Controleer onderstaande gegevens goed voordat je ze
        verstuurt. Heb je alles naar waarheid ingevuld? Dit is
        heel belangrijk. want hier baseren we ons bod op</p>
      <!-- Rest of the content of this step is down below -->
    </div>

    <div class="container" fxFlex="50" *ngIf="pageDetails  && showResumeLaterText == false">
      <div class="sell-window">
        <h2 class="sell-title"><b>Deze auto wil je verkopen</b></h2>
        <div fxLayout="row">
          <img class="sell-image" [src]="carPreviewImage">
          <div class="sell-table">
            <table>
              <tbody>
                <tr>
                  <td> Kenteken: <b>
                      <app-form [show]="['v_registration']" [fields]="pageDetails.fields"></app-form>
                    </b></td>
                </tr>
                <tr>
                  <td> Merk: <b><span style="text-transform: uppercase;">
                        <app-form [show]="['v_make']" [fields]="pageDetails.fields"></app-form>
                      </span></b></td>
                </tr>
                <tr>
                  <td> Model: <b><span style="text-transform: uppercase;">
                        <app-form [show]="['v_modelSpecific']" [fields]="pageDetails.fields"></app-form>
                      </span></b></td>
                </tr>
                <tr>
                  <td> Bouwjaar: <b>
                      <app-form [show]="['v_year']" [fields]="pageDetails.fields"></app-form>
                    </b></td>
                </tr>
                <tr>
                  <td> Brandstof: <b>
                      <app-form [show]="['vs_fuelTypeId']" [fields]="pageDetails.fields"></app-form>
                    </b></td>
                </tr>
              </tbody>
            </table>
            <a (click)="cancelRequest()" href="javascript:void(0)">Aanvraag annuleren</a>
          </div>
        </div>
      </div>

      <div *ngIf="currentStep == 1" class="additional-comments">
        <app-form (formChange)="formChange($event)"
          [hint]="'Denk daarbij aan informatie over eventuele beschadigingen of extra opties'"
          [show]="['vs_commentVehicleInfoCustomer']" [fields]="pageDetails.fields"></app-form>
      </div>
    </div>
  </div>

  <!--   File upload view  -->
  <div class="container" [@fade]="!loading" style="padding-top: 30px;"
    *ngIf="currentStep == 3  && showResumeLaterText == false">
    <section>
      <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="5%">
        <div fxFlex="30">
          <p class="image-title">Linksvoor (verplicht)</p>
          <img class="placeholder-image" [src]="images.front_left.current">
          <file-pond (onaddfile)="setPhotoType($event, 'front_left')" [options]="pondOptions"></file-pond>
        </div>
        <div fxFlex="30">
          <p class="image-title">Rechtsachter (verplicht)</p>
          <img class="placeholder-image" [src]="images.rear_right.current">
          <file-pond (onaddfile)="setPhotoType($event, 'rear_right')" [options]="pondOptions"></file-pond>
        </div>
        <div fxFlex="30">
          <p class="image-title">Interieur (verplicht)</p>
          <img class="placeholder-image" [src]="images.interior.current">
          <file-pond (onaddfile)="setPhotoType($event, 'interior')" [options]="pondOptions"></file-pond>
        </div>
      </div>

      <div fxLayout.xs="column" fxLayout="row" fxLayoutGap="5%">
        <div fxFlex="30">
          <img class="placeholder-image" [src]="images.mileage.current">
          <p class="image-title">Tellers met draaiende motor (verplicht)</p>
          <file-pond (onaddfile)="setPhotoType($event, 'mileage')" [options]="pondOptions"></file-pond>
        </div>
        <div fxFlex="30">
          <img class="placeholder-image" [src]="images.dashboard.current">
          <p class="image-title">Dashboard geheel (verplicht)</p>
          <file-pond (onaddfile)="setPhotoType($event, 'dashboard')" [options]="pondOptions"></file-pond>
        </div>
        <div fxFlex="30">
          <img class="placeholder-image" src="../../../../assets/images/templates/2134/icon-damages.svg">
          <p class="image-title">Schades (optioneel)</p>
          <file-pond (onaddfile)="setPhotoType($event, 'other')" [options]="pondOptionsMultiple"></file-pond>
        </div>

        <form *ngIf="form && form.get('images_count')" [formGroup]="form" style="display: none;">
          <section>
            <mat-form-field appearance="fill">
              <input formControlName="images_count" type="number" matInput>
            </mat-form-field>
          </section>
        </form>
      </div>
    </section>

    <p>Na het toevoegen van de foto's is de taxatie afgerond en ontvang je binnen 1 uur een gegarandeerd bod voor jouw
      auto. Dit bod is helemaal vrijblijvend. Jij bepaalt zelf of je dit bod accepteert. Dus jij hebt alles zelf in de
      hand. Ga je akkoord? Wij komen je auto altijd gratis ophalen!</p>
    <!-- <button [disabled]="loading" (click)="resumeLater()" mat-raised-button color="primary">Later toevoegen</button> -->
  </div>

  <div class="container" [@fade]="!loading" style="padding-top: 30px;" *ngIf="showResumeLaterText">
    <h2>Later afronden</h2>
    <p>Je hebt ervoor gekozen om later foto’s toe te voegen. </p>
    <p>
      Je ontvangt een email met daarin een link om foto’s toe te voegen en de taxatie af te ronden.
      Na het toevoegen van de foto's ontvang je binnen 1 uur een gegarandeerd bod voor jouw auto. Dit bod is helemaal
      vrijblijvend.
      Jij bepaalt zelf of je dit bod accepteert. Dus jij hebt alles zelf in de hand. Akkoord? Wij komen je auto altijd
      gratis ophalen!
    </p>
    <p>Alsnog foto’s toevoegen?</p>
    <div fxLayout="col" fxLayoutAlign="left left">
      <button (click)="resumeSpecification()" style="margin-left: -2px;" mat-raised-button color="primary">Nu
        uploaden</button>
    </div>
  </div>

  <!-- Overview view  -->
  <div style="padding-top: 30px;" *ngIf="currentStep == 4" class="container">
    <mat-accordion *ngIf="pageDetails">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Stap 1 - Voertuig
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section>
          <app-form (formChange)="formChange($event)" [show]="['vs_mileage']" [fields]="pageDetails.fields"></app-form>
        </section>

        <section>
          <app-form (formChange)="formChange($event)" [show]="['vs_atlCode']" [fields]="pageDetails.fields">
          </app-form>
        </section>

        <mat-label>Opties</mat-label>
        <section class="checkbox-grid">
          <mat-checkbox style="padding-right: 10px;" *ngFor="let option of options" [checked]="option.selected"
            id="{{option.id}}" (change)="optionChanged($event.checked, option.id)">{{option.description}}</mat-checkbox>
        </section>

        <section>
          <form *ngIf="form" [formGroup]="form">
            <section *ngIf="form && form.get('vs_vatMarginTypeId_question')">
              <mat-form-field appearance="fill">
                <mat-label>Staat de auto op naam van uw bedrijf?</mat-label>
                <mat-select (ngModelChange)="updateVatMarginType($event)" formControlName="vs_vatMarginTypeId_question">
                  <mat-option [value]="1">Ja</mat-option>
                  <mat-option [value]="2">Nee</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="(form!.controls['vs_vatMarginTypeId_question'].dirty || form!.controls['vs_vatMarginTypeId_question'].touched)">
                  Staat de auto op naam van uw bedrijf is een veld</mat-error>
              </mat-form-field>
            </section>

            <section
              *ngIf="form && form.get('vs_vatMarginTypeId') && form.controls['vs_vatMarginTypeId_question'].value == '1'">
              <mat-form-field appearance="fill">
                <mat-label>Bent u met uw bedrijf BTW plichtig?</mat-label>
                <mat-select formControlName="vs_vatMarginTypeId">
                  <mat-option [value]="1">Ja</mat-option>
                  <mat-option [value]="2">Nee</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="(form!.controls['vs_vatMarginTypeId'].dirty || form!.controls['vs_vatMarginTypeId'].touched)">
                  Staat de auto op naam van uw bedrijf is een veld</mat-error>
              </mat-form-field>
            </section>
          </form>
        </section>

        <section>
          <form *ngIf="form" [formGroup]="form">
            <section *ngIf="form && form.get('vs_conditionBodyTypeId')">
              <mat-form-field appearance="fill">
                <mat-label>Conditie buitenkant</mat-label>
                <mat-select value="" formControlName="vs_conditionBodyTypeId">
                  <mat-option value="3">Goede conditie</mat-option>
                  <mat-option value="2">Redelijke conditie</mat-option>
                  <mat-option value="1">Slechte conditie</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="(form!.controls['vs_conditionBodyTypeId'].dirty || form!.controls['vs_conditionBodyTypeId'].touched)">
                  Conditie buitenkant is een verplicht veld</mat-error>
              </mat-form-field>
            </section>
          </form>

          <form *ngIf="form" [formGroup]="form">
            <section *ngIf="form && form.get('vs_conditionTechnicalTypeId')">
              <mat-form-field appearance="fill">
                <mat-label>Conditie technisch</mat-label>
                <mat-select value="" formControlName="vs_conditionTechnicalTypeId">
                  <mat-option value="3">Goede conditie</mat-option>
                  <mat-option value="2">Redelijke conditie</mat-option>
                  <mat-option value="1">Slechte conditie</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="(form!.controls['vs_conditionTechnicalTypeId'].dirty || form!.controls['vs_conditionTechnicalTypeId'].touched)">
                  Conditie technisch is een verplicht veld</mat-error>
              </mat-form-field>
            </section>
          </form>

          <section *ngIf="form!.controls['vs_conditionTechnicalTypeId'].value == 1">
            <app-form (formChange)="formChange($event)" [show]="['vs_commentTechnicalDefects']"
              [fields]="pageDetails.fields">
            </app-form>
          </section>
        </section>

        <section>
          <app-form (formChange)="formChange($event)" [show]="['vs_smoked', 'vs_pets']" [fields]="pageDetails.fields">
          </app-form>
        </section>

        <app-form (formChange)="formChange($event)"
          [hint]="'Denk daarbij aan informatie over eventuele beschadigingen of extra opties'"
          [show]="['vs_commentVehicleInfoCustomer']" [fields]="pageDetails.fields"></app-form>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Stap 2 - Persoonlijke gegevens
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section>
          <app-form (formChange)="formChange($event)" [show]="['c_salutationTypeId', 'c_lastName', 'c_city']"
            [fields]="pageDetails.fields"></app-form>
        </section>

        <section>
          <app-form [hint]="'Wij hebben uw email nodig om biedingen met u te communiceren.'"
            (formChange)="formChange($event)" [show]="['c_emailAddress']" [fields]="pageDetails.fields"></app-form>
        </section>

        <section>
          <app-form
            [hint]="'Uw telefoonnummer kan makkelijk zijn als wij vragen hebben over uw auto. Dit veld is niet verplicht'"
            (formChange)="formChange($event)" [show]="['c_phone']" [fields]="pageDetails.fields"></app-form>
        </section>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Stap 3 - Fotos
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section>
          <app-form [show]="['vs_images']" [fields]="pageDetails.fields"></app-form>
        </section>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div *ngIf="!form?.valid && submitPressed" fxLayout="row" class="container">
    <h3 style="color:red">Niet alle verplichte velden zijn ingevuld om verder te kunnen gaan.</h3>
  </div>

  <div *ngIf="currentStep == 4">
    <div style="padding-top: 20px;">
      <p fxLayout="row" class="container">Na het klikken op "Aanvraag versturen" is de taxatie afgerond, en ontvang je
        binnen 1 uur een gegarandeerd bod voor jouw auto. Dit bod is helemaal vrijblijvend. Jij bepaalt zelf of je dit
        bod accepteert. Dus jij hebt alles zelf in de hand. Ga je akkoord? Wij komen je auto altijd gratis ophalen!</p>
      <section fxLayout="row" class="container checkbox-grid">
        <mat-checkbox (change)="registerToMailchimpNewsletter($event)">Abonneren op onze nieuwsbrief?</mat-checkbox>
      </section>
    </div>
    <div fxLayout="row">
      <button [disabled]="loading" (click)="submitValuation()" mat-raised-button color="primary">Aanvraag
        versturen</button>
    </div>
  </div>

  <div *ngIf="showResumeLaterText == false" class="sticky-buttons" style="padding-top: 30px;" fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <button [disabled]="loading" *ngIf="currentStep == 1" (click)="cancelRequest()" mat-raised-button
        color="warn">Annuleren</button>
      <button [disabled]="loading" *ngIf="currentStep <= 3 && currentStep > 1" (click)="previousStep()"
        mat-raised-button color="warn">Vorige stap</button>
    </div>

    <div [matTooltipDisabled]="form?.valid" matTooltip="Niet alle verplichte velden zijn ingevuld">
      <button type="submit" *ngIf="currentStep <= 3" (click)="nextStep()" mat-raised-button color="primary">Volgende
        stap</button>
    </div>
  </div>